<template>
	<div class="flex">
		<div class="vh100 b-r-3">
			<div class="c-f p-l-10 w-60 l-h-30 b-b-3 f-12 cursor"  @click="checkgroup(group,index)" v-for="(group, index) in configTools" :key="index" :class="checkgroupkey==index?'c-eff bg-25':''" >
				{{ group.groupName }}
			</div>
			
		</div>

		<div :class="$style.box">
			<!-- v-for="(group, index) in configTools" :key="index" -->
			<!-- v-if="!group.hidden" -->
			
			<div >
				<template  v-if="!group.hidden">
					<draggable v-model="configTools[checkgroupkey].componentList" :class="$style.groupList" :sort="false"
						:filter="`.${$style.disabled}`" :group="{ name: dragGroup, pull: 'clone', put: false }"
						:clone="cloneDog" @filter="$emit('onFilter')" @start="$emit('onDragStart')"
						@end="$emit('onDragEnd')">
						<div v-for="item in configTools[checkgroupkey].componentList" :key="item.title" :class="{
								[$style.listItem]: true,
								draggableToolItem: true,
								[$style.disabled]: (currentUseComponentNum[item.componentPack.componentViewName] || 0) >= item.maxNum
							}">
							<div class="flex">
								<p :class="[$style.line, $style.lineIcon]">
									<i :class="item.icon"></i>
								</p>
								<p :class="[$style.line, $style.lineTitle]">
									{{ item.title }}
								</p>
								<!-- <p :class="[$style.line, $style.lineUseNum]">
									{{ currentUseComponentNum[item.componentPack.componentViewName] || 0 }} /
									{{ item.maxNum }}
								</p> -->
							</div>
						</div>
					</draggable>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import Draggable from 'vuedraggable';

	import {generateEditorItem	} from './common/editorData';

	export default {
		name: 'EditorToolBar',
		data(){
			return{
				checkgroupkey:0,
				group:[]
			}
		},
		components: {
			Draggable,
		},
		props: {
			configTools: {
				type: Array,
				default: () => []
			},
			currentUseComponentNum: {
				default: () => ({}),
				type: Object
			},
			dragGroup: {
				default: '',
				type: String
			}
		},
		watch: {
			currentUseComponentNum(newVal) {
				console.log(newVal);
			},
			configTools(e){
				this.group=e
				console.log('configTools',e)
			}
		},
		methods: {
			// clone 当前模块
			cloneDog(toolItem) {
				return generateEditorItem(toolItem);
			},
			checkgroup(item,index){
				this.group=[item]
				this.checkgroupkey=index
			}
		}
	};
</script>

<style module>
	@import "../demo-common/css/variable.css";

	.box {
		width: 100%;
	}


	.groupName {
		font-size: 12px;
		line-height: 18px;
		color: #fff;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.groupList {
		display: flex;
		flex-wrap: wrap;
		/* padding: 0 10px; */
		justify-content: space-between;
		padding:10px;
	}

	.listItem {
		position: relative;
		width: 47%;
		max-width: 120px;
		/* 避免拖动ghost样式异常 */
		height: 30px;
		cursor: move;
		flex-shrink: 0;
		transition: box-shadow 0.3s ease;
		display: flex;
		/* text-align: center; */
		align-items: center;
		/* justify-content: center; */
		color: #fff;
		border-radius: 8px;
		padding: 0 7px;
		border:1px solid #333;
		margin-bottom:10px;
		background-color: var(--color-252525);
	}
	.listItem:hover {
		/* background-color: var(--color-474747); */
		box-shadow: 0 0 8px 1px rgba(220, 255, 255, .5);
		
	}

	.line {
		font-size: 12px;
		line-height: 18px;
		
	}

	.lineIcon {
		color:#fff;
		font-size: 14px;
		margin-right:3px;
	}

	.disabled {
		cursor: no-drop;
		opacity: 0.8;
	}
</style>
