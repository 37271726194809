<template>
	<BaseEditorHeader default-active="3">
		<!-- <el-select size="mini" v-model="platform" placeholder="请选择" :class="$style.selectPlatform">
			<el-option label="PC" value="editor"></el-option>
			<el-option label="M" value="editorM"></el-option>
		</el-select> -->
		<!-- <div class="bg-f m-r-10 p-0-10 h-30 flex l-h-30 rad-10">
			<el-button size="mini" icon="el-icon-minus" :disabled="disabledMinus" :class="$style.scaleBtn" circle @click="handleMinus"></el-button>
			<div class="p-0-10 c-b14e">{{ value }}%</div>
			<el-button size="mini" icon="el-icon-plus" :disabled="disabledPlus" :class="$style.scaleBtn" circle @click="handlePlus">
			</el-button>
		</div> -->
		<div class="c-f f-12 p-0-7 cursor h-20 l-h-20" @click="$emit('onPreview')">预览</div>
		<!-- <div class="c-f f-12 p-0-7 cursor h-20 l-h-20" @click="$emit('onSave')">保存</div> -->
		<div class="c-f f-12 p-0-7 cursor h-20 l-h-20" @click="$emit('onPublish')">发布</div>
	<!-- 	<el-button size="mini" type="text" ><span class="c-f"></span></el-button>
		<el-button size="mini" type="text" plain @click="$emit('onSave')" ><span class="c-f">保存</span> </el-button>
		<el-button size="mini" type="text" @click="$emit('onPublish')"><span class="c-f">发布</span></el-button> -->
	</BaseEditorHeader>
</template>

<script>
	import BaseEditorHeader from '../demo-common/components/EditorHeader.vue';

	export default {
		name: 'EditorHeader',
		components: {
			BaseEditorHeader
		},
		props: {
			value: {
				type: Number,
				default: 60
			},
			minScale: {
				type: Number,
				default: 40
			},
			stepNum: {
				type: Number,
				default: 5
			}
		},
		computed: {
			platform: {
				get() {
					return this.$route.name;
				},
				set(routerName) {
					this.$router.replace({
						name: routerName
					});
				}
			},
			disabledMinus() {
				return this.value <= this.minScale;
			},
			disabledPlus() {
				return this.value >= 100;
			},
		},
		methods: {
			handlePlus() {
				const curScale = this.value + this.stepNum;
				this.emitUpdateScale(curScale);
			},
			handleMinus() {
				const curScale = this.value - this.stepNum;
				this.emitUpdateScale(curScale);
			},
			emitUpdateScale(curScale) {
				this.$emit('input', curScale);
				this.$emit('onUpdateScale', {
					scale: curScale
				});
			}
		}
	};
</script>
<style scropt>
	.el-button.is-plain:hover{
		background:unset !important
	}
	.cursor:hover{box-shadow: 0 0 2px 1px rgba(220, 255, 255, .5);border-radius:3px}
</style>
<style module >
	@import "../demo-common/css/variable.css";

	.box {
		padding: 10px 2%;
		height: auto;
		background: var(--color-black);
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* box-shadow: 0 0 8px 1px rgba(0, 0, 0, .3); */
	}
	
	.headerMenu {
		display: flex;
		align-items: center;
		justify-content: center;

		h1 {
			display: none;
			text-shadow: 0 0 40px #409EFF;
			font-size: 26px;
			text-transform: uppercase;
			z-index: 10;
		}
	}
	
	.selectPlatform {
		width: 100px;
		margin-right: 20px;
	}
</style>
